<!-- 商家端 - 完善主体信息 -->
<template>
  <div class="contractInfo">
      <div class="title">
        <strong class="form-title__text">完善主体信息</strong>
      </div>
      <div class="improve-cont">
        <div class="form-title">
          <strong class="form-title__text">当前银行卡信息：</strong>
        </div>
        <div class="wrap-card">
          <div class="box-card">
            <div class="item">
              开户行：{{ brandCardInfo.cardholder }}
            </div>
            <div class="item">
              开户名：{{ brandCardInfo.bankName }}
            </div>
            <div class="item">
              账号：{{ brandCardInfo.cardNumber }}
            </div>
            <!-- <div class="item" v-if="brandCardInfo.bankCode">
              联行号：{{ brandCardInfo.bankCode }}
            </div>
            <div class="item" v-if="brandCardInfo?.attachmentJsonList?.length">
              开户行许可证/收款银行确认函：<el-button size="small" @click="previewImage(brandCardInfo.attachmentJsonList)" type="text">查看</el-button>
            </div> -->
          </div>
        </div>
      </div>
      <!-- 关联现有主体信息 -->
      <div class="improve-cont">
        <div class="form-title">
          <strong class="form-title__text">关联现有主体信息：</strong>
        </div>
        <div class="improve-cont-right">
          <div class="right-top">
            <span>找不到主体信息，可以点击</span>
            <el-button @click="addContractInfo" size="mini" type="primary" plain>新增主体</el-button>
          </div>
          <div class="wrap">
            <el-table
              :data="contractInfoList"
              style="width: 100%"
              max-height="600px"
              border
              :header-row-style="headerRowStyle"
              :header-cell-style="headerCellStyle"
              >
              <el-table-column label="主体类型" prop="merchantTypeName"></el-table-column>
              <el-table-column min-width="100px" label="主体名称">
                <template slot-scope="scope">
                  <p v-if="scope.row.merchantType === 'ENTERPRISE'">{{ scope.row.company }}</p>
                  <p v-else>{{ scope.row.contactRealName }}</p>
                </template>
              </el-table-column>
              <!-- <el-table-column min-width="100px" label="甲方主体名称" prop="ownCompanyName"></el-table-column> -->
              <el-table-column min-width="150px" label="联系人信息">
                <template slot-scope="scope">
                  <p>联系人：{{ scope.row.contactRealName || '-' }}</p>
                  <p>证件号码：{{ scope.row.contactIdcardNo || '-' }}</p>
                  <p>联系电话：{{ scope.row.contactPhone || '-' }}</p>
                  <p>联系地区：{{ scope.row.contactAddress || '-' }}</p>
                </template>
              </el-table-column>
              <el-table-column min-width="60px" label="操作" prop="attachmentJsonList">
                <template slot-scope="{ row }">
                  <el-button size="small" @click="handeChecked(row)" type="text">{{row.isChecked ? '取消选择' : '选择'}}</el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
      <el-image
        ref="elImage"
        style="width: 0; height: 0;"
        :src="bigImageUrl"
        :preview-src-list="logicImageList">
      </el-image>
      
      <div slot="footer" class="dialog-footer">
        <el-button @click="backPage" size="medium">取 消</el-button>
        <button-hoc size="medium" type="primary" @click="submitForm" :loading="formLoading">保 存</button-hoc>
      </div>
  </div>
</template>

<script>
import { getlistByBankCardForApps, bindContractInfo, distributorBankCardGet } from '@/api/subject-information';
export default {
  name: 'improveInformation',
  data() {
    return {
      id: '',
      contractInfoList: [], // 主体信息列表
      brandCardInfo: {},
      formLoading: false,
      contractInfoIdList: [],
      bigImageUrl: '',
      logicImageList: [],
      headerRowStyle: { color: '#333333' },
      headerCellStyle: { backgroundColor: '#F3F3F3', fontWeight: '400', fontSize: '12px' },
    };
  },
  computed: {
    
  },
  created() {
    this.id = this.$route.query.id;
    if(this.id) {
      this.getBandCardInfo();
      this.getDataInfo();
    }
    if (window.history && window.history.pushState) { 
      // 回退监听事件
      window.addEventListener('popstate', this.backPage, { once: true });
    }
  },
  methods: {
    // 返回
    backPage() {
      this.$router.replace('/syoung/shop/detail?activeName=second');
    },
    // 添加主体信息
    addContractInfo() {
      this.$router.push('/syoung/shop/contract-info');
    },
    handeChecked(row) {
      this.contractInfoList.map(item => {
        if (item.id === row.id) {
          item.isChecked = !item.isChecked;
        }
      });
      const index = this.contractInfoIdList.indexOf(row.id);
      if (index === -1) {
        this.contractInfoIdList.push(row.id);
      } else {
        this.contractInfoIdList.splice(index, 1);
      }
    },
    getDataInfo() {
      getlistByBankCardForApps({ 
        distribtorBankCardId: this.id 
      })
        .then(res => {
          if(res.code === '0') {
            this.contractInfoList = res.data.map(item => Object.assign(item, { isChecked: false }));
          }
      });
    },
    // 获取收付款账号信息
    getBandCardInfo() {
      distributorBankCardGet({ id: this.id }).then(res => {
        this.brandCardInfo = { ...res?.data, attachmentJsonList: res?.data?.attachmentJson ? JSON.parse(res.data.attachmentJson) : [] };
      });
    },
    // 保存
    submitForm() {
      const { contractInfoIdList, brandCardInfo } = this;
      if(!contractInfoIdList.length) return this.$message({ message: `请选择主体信息`, type: 'warning' });
      this.formLoading = true;
      const params = {
        contractInfoIdList,
        id: brandCardInfo.id
      };
      bindContractInfo(params).then(res => {
        if(res.code === '0') {
          this.$message({
            message: `操作成功`,
            type: 'success'
          });
          setTimeout(() => {
            this.backPage();
          }, 1000);
        }
      });
    },
    previewImage(list) {
      this.logicImageList = list;
      this.$nextTick(() => {
        this.$refs.elImage.clickHandler();
      });
    },
  }
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
::v-deep {
  .el-table th > .cell {
    white-space: pre-line;
  }
}
.contractInfo {
  padding: 0 20px;
  ::v-deep {
    .el-input,
    .el-select,
    .el-textarea {
      width: 280px;
    }
  }

  .link {
    color: var(--color-primary);
    margin: 0 20px;
  }
}

.custom-label {
  li {
    line-height: 20px;
  }
}
.title {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 20px;
}
.tips-title {
  display: flex;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 30px;
  margin-left: 10px;
}
.form-title {
  // display: flex;
  // align-items: center;
  &__text {
    display: flex;
    align-items: center;
    min-width: 120px;
    font-size: 14px;
    color: var(--color-text-primary);
    font-weight: bold;
    &:before {
      content: '';
      height: 12px;
      width: 2px;
      background: var(--color-primary);
      margin-right: 4px;
    }
  }
}
.dialog-footer {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.improve-cont {
  display: flex;
  margin-top: 40px;
}

.wrap {
  width: 1000px;
  box-sizing: content-box;
  margin-top: 20px;
}
.wrap-card {
  width: 627px;

  .box-card {
    width: 627px;
    border: 1px solid #bbb;
    border-radius: 4px;
    font-size: 14px;
    padding: 25px 20px;
    .item {
      line-height: 24px;
    }
  }
}

.right-top {
  display: flex;
  align-items: center;
  > span {
    color: #999;
    margin-right: 15px;
  }
}
</style>
